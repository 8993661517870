import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Create,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    useNotify,
    useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/Layout';
import Error from '../../components/Error';
import DowlinkCreateActions from '../components/DownlinkCreateActions';
import NextWindow from '../components/NextWindow';
import useCreateCommand from '../hooks/useCreateCommand';
import classNames from 'classnames';
import InputWrapper from '../components/InputWrapper';
import Label from '../../components/Label';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    siteTitle: {
        opacity: 0.6,
    },
    card: {
        alignSelf: 'center',
    },
    form: {
        padding: '50px 130px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 30,
    },
    nextWindow: {
        maxWidth: 360,
        margin: '5px 0 0 0',
        fontSize: 14,
        lineHeight: '1.3rem',
    },
    value: {
        '& .MuiFormHelperText-root': {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            width: 100,
        },
    },
    largeInput: {
        width: 360,
    },
}));

const SelectInputOption = (param) => `${param.id} - ${param.name} (${param.unit})`;

const CreateCommand = () => {
    const classes = useStyles();
    const { state } = useLocation();
    const {
        deviceId,
        initialRecord,
        callbackUrl,
        deviceParamsArray,
        selectedParam,
        nextWindow,
        error,
        onChangeSelectHandler,
        validateValue,
    } = useCreateCommand(state);
    const notify = useNotify();
    const redirect = useRedirect();
    // Error
    if (error)
        return (
            <>
                <Error message={`${error}. Please cancel and start over`} />
                <DowlinkCreateActions cancelUrl={callbackUrl} saveBtnVisible={false} />
            </>
        );

    return (
        <Layout classes={classes.container}>
            <Typography variant="h5" gutterBottom>
                Schedule new downlink command
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" underline="hover" href="/devices">
                    Devices
                </Link>
                <Link underline="hover" color="primary" href={`/devices/${state.deviceUuid}/show`}>
                    {state.deviceId}
                </Link>
                <Link underline="hover" color="primary" href={`${callbackUrl}`}>
                    Downlink
                </Link>
                <Typography color="inherit">Schedule new downlink command</Typography>
            </Breadcrumbs>
            <Typography variant="h6" className={classes.siteTitle}>
                Device {deviceId}
            </Typography>
            <Create
                resource="downlinkCommands"
                record={initialRecord}
                className={classes.card}
                mutationOptions={{
                    onSuccess: () => {
                        notify('Downlink command scheduled');
                        redirect(callbackUrl);
                    },
                    onError: (error) => {
                        let errorMessage = 'Downlink command was not created';

                        try {
                            if (error && error.message) {
                                const messageObject = JSON.parse(error.message);
                                errorMessage = messageObject.message;
                            }
                        } catch (error) {
                            errorMessage = 'Downlink command was not created';
                        }

                        notify(errorMessage, {
                            type: 'error',
                        });
                    },
                }}
            >
                <SimpleForm
                    className={classes.form}
                    toolbar={<DowlinkCreateActions cancelUrl={callbackUrl} />}
                >
                    <InputWrapper source="commandId">
                        <SelectInput
                            label="Parameter"
                            source="commandId"
                            choices={deviceParamsArray}
                            optionValue="id"
                            validate={[required()]}
                            translateChoice={false}
                            variant="outlined"
                            onChange={onChangeSelectHandler}
                            optionText={SelectInputOption}
                            className={classes.largeInput}
                        />
                    </InputWrapper>
                    <InputWrapper source="commandValue">
                        <NumberInput
                            label="Value"
                            source="commandValue"
                            variant="outlined"
                            id="commandValue"
                            validate={[required(), validateValue]}
                            helperText={selectedParam.hint}
                            className={classNames(classes.largeInput, classes.value)}
                        />
                    </InputWrapper>

                    <Box sx={{ marginTop: '20px' }}>
                        <Label>Next window</Label>
                        <NextWindow
                            label="Next Window"
                            className={classNames(classes.largeInput, classes.nextWindow)}
                            nextWindow={nextWindow.nextWindow}
                            nextWindowDistance={nextWindow.nextWindowDistance}
                            waitingFirstMessage={nextWindow.waitingFirstMessage}
                            loading={nextWindow.loading}
                            error={nextWindow.error}
                        />
                    </Box>
                </SimpleForm>
            </Create>
        </Layout>
    );
};

export default CreateCommand;
