import React, { useEffect, useState } from 'react';
import { useRedirect, useNotify } from 'react-admin';
import { createTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    FormControl,
    Button,
    ThemeProvider,
    Link,
    Snackbar,
    LinearProgress,
    CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Theme from '../theme/index';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import PasswordField from '../components/PasswordField';
import { resetPassword } from '../utils/authProvider';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
        width: '100%',
        textAlign: 'left',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
        marginBottom: '4px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '45px',
        width: '100%',
        marginTop: '32px',
        '&:hover': {
            color: 'white',
            border: '1px solid #00AEEF',
        },
    },
    snackbar: {
        backgroundColor: '#EA2F2F',
        color: 'white',
        borderRadius: '8px',
        padding: '4px 4px',
    },
}));

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&'); //eslint-disable-line
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), //eslint-disable-line
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function parseJwt(token) {
    var base64Url = token.split('.')[0];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64) //eslint-disable-line
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

const SetPassword = (props) => {
    const { isForSignup } = props;
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();

    const token = getParameterByName('data');
    const code = getParameterByName('code');
    const username = parseJwt(token).userName;

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        let mounted = true;

        fetch(process.env.REACT_APP_API_BASE_URL + `/users/checkCode/${username}?code=${code}`, {
            method: 'GET',
            signal: signal,
        })
            .then((res) => {
                if (mounted) {
                    setLoading(false);
                    if (res.status !== 200) {
                        setHasError(true);
                        setError('Error while validating link');
                    }
                }
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log('Aborted');
                } else {
                    if (mounted) {
                        setLoading(false);
                        setHasError(true);
                        setError('Error while validating link');
                    }
                }
            });
        //clean up effect
        return () => {
            controller.abort();
            mounted = false;
        };
    }, [code, username]);

    const handleSaveAuth0 = async (e) => {
        if (!newPassword || !confirmPassword) {
            e.preventDefault();
            notify('Password is required.', { type: 'error' });
            return;
        }

        //Password policy validation
        if (newPassword.length < 8) {
            e.preventDefault();
            notify('Password has to be at least 8 characters.', { type: 'error' });
            return;
        }

        if (newPassword !== confirmPassword) {
            e.preventDefault();
            notify('Passwords need to match.', { type: 'error' });
            return;
        }

        if (newPassword !== confirmPassword) {
            e.preventDefault();
            notify('Passwords need to match.', { type: 'error' });
            return;
        }

        const uppercase = /[A-Z]/;
        const lowercase = /[a-z]/;
        const number = /[0-9]/;

        if (
            !uppercase.test(newPassword) ||
            !lowercase.test(newPassword) ||
            !number.test(newPassword)
        ) {
            e.preventDefault();
            notify('Password must contain a lowercase, uppercase and number.', { type: 'error' });
            return;
        }

        //reset password
        setSubmitting(true);
        try {
            const res = await resetPassword(username, code, newPassword);
            setSubmitting(false);
            let json = await res.json();
            if (res.status === 200) {
                notify('Password has been set successfully. Please login to continue.', {
                    type: 'success',
                });
                redirect('/login');
                return;
            } else {
                // 4xx response includes an object wih a message property in the result json
                // while 5xx response includes a direct string of error message
                if (typeof json === 'object') json = json.message;

                if (res.status === 404) {
                    notify('This link is either expired or invalid. Please request a new link.', {
                        type: 'error',
                    });
                    return;
                }

                if (json && json.includes('PasswordHistoryError')) {
                    notify(
                        'Invalid password. Password has previously been used, please enter a new password.',
                        { type: 'error' },
                    );
                    return;
                }
                if (json && json.includes('PasswordStrengthError')) {
                    notify(
                        'Invalid password. Password does not meet the password strength requirement.',
                        { type: 'error' },
                    );
                    return;
                }
                if (json && json.includes('PasswordNoUserInfoError')) {
                    notify('Invalid password. Password contains user information.', {
                        type: 'error',
                    });
                    return;
                }
                if (json && json.includes('PasswordDictionaryError')) {
                    notify('Invalid password. Password is too common.', { type: 'error' });
                    return;
                }
                if (res.status === 400) {
                    notify(
                        'Invalid password. Please make sure your password meet the password strength requirement.',
                        { type: 'error' },
                    );
                    return;
                }
                notify(json ?? 'Something went wrong. Please try again', { type: 'error' });
            }
        } catch (error) {
            setSubmitting(false);
            notify('Something went wrong. Please try again', { type: 'error' });
        }
    };

    const handleCloseAlert = () => {
        setHasError(false);
    };

    if (loading) {
        return (
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Card style={{ padding: '16px', width: '360px', textAlign: 'center' }}>
                    <LinearProgress />
                </Card>
            </Box>
        );
    }

    if (!loading && hasError) {
        return (
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card style={{ padding: '16px', width: '360px', textAlign: 'center' }}>
                    <div style={{ marginBottom: '8px' }}>
                        <ErrorIcon style={{ width: '3em', height: '3em' }} />
                    </div>
                    <span className={classes.label}>
                        This link has been expired or is invalid. Please request a new link.
                    </span>
                    <div style={{ marginTop: '16px' }}>
                        <Link style={{ cursor: 'pointer' }} onClick={() => redirect('/login')}>
                            Go to Login
                        </Link>
                    </div>
                </Card>
            </Box>
        );
    }

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card style={{ padding: '16px', width: '360px', textAlign: 'center' }}>
                    <h2>{isForSignup ? 'Confirm Signup' : 'Reset Password'}</h2>
                    <FormControl required className={classes.formControl}>
                        <span className={classes.label}>
                            {isForSignup
                                ? 'Please set up your password.'
                                : 'Please enter your new password.' +
                                  ' The password must be at least 8 characters long.'}
                        </span>
                        <PasswordField
                            name="newPassword"
                            variant="outlined"
                            placeholder="Password"
                            fullWidth={true}
                            value={newPassword}
                            onChange={setNewPassword}
                        />
                    </FormControl>

                    <FormControl required className={classes.formControl}>
                        <span className={classes.label}>
                            {isForSignup
                                ? 'Please confirm your password.'
                                : 'Please confirm your new password.'}
                        </span>
                        <PasswordField
                            name="confirmPassword"
                            variant="outlined"
                            placeholder="Confirm Password"
                            fullWidth={true}
                            value={confirmPassword}
                            onChange={setConfirmPassword}
                        />
                    </FormControl>

                    <Button
                        className={classes.submit}
                        color="primary"
                        variant="outlined"
                        onClick={handleSaveAuth0}
                        disabled={submitting}
                    >
                        {isForSignup ? 'Confirm' : 'Reset Password'}
                        {submitting && (
                            <CircularProgress
                                style={{ marginLeft: '8px' }}
                                size={18}
                                color="inherit"
                            />
                        )}
                    </Button>
                </Card>
                <Box padding="0px 44px" mt="24px" width="360px">
                    <span className={classes.label}>
                        {`If you have problems with ${
                            isForSignup ? 'creating' : 'resetting'
                        } your password, please contact `}
                        <Link href="mailto:support@smarterwater.co.nz">
                            support@smarterwater.co.nz
                        </Link>
                        .
                    </span>
                </Box>
            </Box>
            <Snackbar
                open={hasError}
                onClose={handleCloseAlert}
                autoHideDuration={5000}
                className={classes.snackbar}
            >
                <div className={classes.snackbar}>{error}</div>
            </Snackbar>
        </ThemeProvider>
    );
};

export default SetPassword;
