import React, { useEffect } from 'react';
import { Layout, ThemeProvider, useAuthState, useLogout } from 'react-admin';
import Menu from './menu/Menu';
import { useAppContext } from './context/AppContext';
import GROUPS from './auth/groups';
import { getUserInfo } from './utils/authProvider';
import PortalSidebar from './menu/Sidebar';
import { Box } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import Theme from './theme/index';
import Loading from './components/Loading';

const PortalLayout = (props) => {
    const { authenticated, isLoading } = useAuthState();
    const [appStore, setAppStore] = useAppContext();
    const handleLogout = useLogout();

    useEffect(() => {
        const getIdentity = async () => {
            try {
                const userInfo = await getUserInfo();
                const groups = userInfo.roles;

                if (!appStore.userId || userInfo.externalUserId !== appStore.userId) {
                    setAppStore({
                        organisationId:
                            groups.includes(GROUPS.HSW_USER) || groups.includes(GROUPS.HSW_ADMIN)
                                ? appStore.organisationId
                                : userInfo.organisationId,
                        userId: userInfo.externalUserId,
                        email: userInfo.email ?? '',
                    });
                }
            } catch (err) {
                handleLogout();
                return;
            }
        };
        if (authenticated && !isLoading) getIdentity();

        if (!isLoading && !authenticated) {
            handleLogout();
        }
    }, [authenticated, appStore, handleLogout, setAppStore, isLoading]);

    if (isLoading || !authenticated) {
        return (
            <ThemeProvider theme={createTheme(Theme)}>
                <Box height="100vh" pt={10}>
                    <Loading />
                </Box>
            </ThemeProvider>
        );
    }

    if (authenticated) {
        return <Layout {...props} menu={Menu} appBar={() => <></>} sidebar={PortalSidebar} />;
    }
};

export default PortalLayout;
