import React, { createContext, useEffect, useContext, useState } from 'react';
import { getTime } from 'date-fns';
import { getAccessToken } from '../../../utils/authProvider';

const ReadingsDataContext = createContext({});

const ReadingsDataContextProvider = ({ children, deviceId, from, to }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const update = async () => {
            setIsLoading(true);
            setError(undefined);
            const fromTimestamp = getTime(from);
            const toTimestamp = getTime(to);
            if (!fromTimestamp || !toTimestamp || fromTimestamp > toTimestamp) return;

            const { data: readingsData, error: errorFetchReadings } = await fetchReadingsData(
                deviceId,
                fromTimestamp,
                toTimestamp,
            );
            setData(readingsData);
            setError(errorFetchReadings);
            setIsLoading(false);
        };

        if (deviceId && from && to) {
            update();
        }
    }, [deviceId, from, to]);

    return (
        <ReadingsDataContext.Provider value={{ data, from, to, isLoading, error }}>
            {children}
        </ReadingsDataContext.Provider>
    );
};

const useReadingsDataContext = () => {
    const context = useContext(ReadingsDataContext);
    if (!context) {
        throw new Error('useReadingsDataContext must be used within a ReadingsDataContextProvider');
    }
    return context;
};

const fetchReadingsData = async (deviceId, fromDate, toDate) => {
    let data = {
        actual: null,
        lost: null,
        expected: null,
    };
    let error;

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/readingssummary?fromDate=${fromDate}&toDate=${toDate}`;
        const token = await getAccessToken();
        const options = {
            method: 'GET',
            headers: new Headers({ Accept: 'application/json', Authorization: token }),
        };

        const res = await fetch(url, options);
        const json = await res.json();
        if (res.status !== 200) {
            throw Error(JSON.stringify(json));
        }

        data = json.summary;
    } catch (err) {
        error = err;
    }

    return {
        data,
        error,
    };
};

export { useReadingsDataContext, ReadingsDataContextProvider };
