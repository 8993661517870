import * as React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Box, Card, Button, LinearProgress, Link } from '@mui/material';
import Theme from '../theme/index';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import MaintenancePage from './MaintenancePage';
import { auth0 } from './auth0Service';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './azureADService';
import { contactLink } from '../constant';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
        width: '100%',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
        marginBottom: '4px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '45px',
        width: '100%',
        marginTop: '32px',
        marginBottom: '24px',
    },
    hyndsStaff: {
        marginTop: '20px',
        display: 'block',
        textTransform: 'none',
    },
}));

const LoginPage = () => {
    const classes = useStyles();
    const { instance } = useMsal();

    // For Dev and Test ONLY
    const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
    const [loading, setLoading] = useState(!isProduction);

    useEffect(() => {
        if (!isProduction) {
            fetch(process.env.REACT_APP_API_BASE_URL + '/status')
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'MAINTENANCE') setIsMaintenanceMode(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error checking maintenance mode:', error);
                    setLoading(false);
                });
        }
    }, [isProduction]);

    if (loading) {
        return <LinearProgress />;
    }
    if (isMaintenanceMode) {
        return <MaintenancePage />;
    }

    const submitAuth0 = async () => {
        await auth0.loginWithRedirect();
    };

    const handleStaffLogin = async (instance) => {
        await instance.loginRedirect(loginRequest);
    };

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card style={{ padding: '16px', maxWidth: '360px', textAlign: 'left' }}>
                    <Button
                        type="submit"
                        onClick={submitAuth0}
                        color="primary"
                        className={classes.submit}
                    >
                        Sign in
                    </Button>
                    <span className={classes.label}>
                        If you don&apos;t have account, please contact the{' '}
                        <Link href={contactLink}>Hynds Aquanect Team</Link>.
                    </span>
                </Card>
                <Box mt="16px" maxWidth="360px" width="100%">
                    <Button
                        className={classes.hyndsStaff}
                        onClick={() => handleStaffLogin(instance)}
                        variant="secondary"
                    >
                        Hynds staff
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default LoginPage;
