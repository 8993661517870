/* eslint-disable security/detect-object-injection */
import React, { useState } from 'react';
import {
    useGetList,
    useCreate,
    required,
    minLength,
    maxLength,
    useRedirect,
    useRefresh,
    useNotify,
    usePermissions,
} from 'react-admin';
import {
    Autocomplete,
    Box,
    Grid,
    Card,
    Button,
    Typography,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    debounce,
    Tooltip,
    Switch,
} from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import { useAppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import SearchIcon from '@mui/icons-material/Search';
import { Marker } from '@react-google-maps/api';
import Map from '../components/Map';
import { makeStyles } from '@mui/styles';
import AsyncButton from '../components/AsyncButton';
import GROUPS from '../auth/groups';
import GoogleAutoComplete from './components/GoogleAutoComplete';
import Label from '../components/Label';
const lib = ['places', 'drawing', 'geometry'];
import { validateText } from '../utils/validation';
import { useSearchParams } from 'react-router-dom';
import {
    siteTypes,
    networkTypes,
    applicationTypesMh,
    applicationTypesGroundwater,
    applicationTypesRaft,
    applicationTypesTank,
} from './components/SiteTypes';
import { getUserInfo } from '../utils/authProvider';

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        width: '100%',
        height: '300px',
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    title: {
        marginTop: '22px',
        height: '43px',
        color: '#0B0B0D',
        fontSize: '32px',
        fontWeight: 'bold',
        letterSpacing: '-0.77px',
        lineHeight: '43px',
    },
    heading: {
        color: '#0B0B0D',
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '-0.38px',
        lineHeight: '21px',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '40px',
        width: '180px',
        '&:hover': {
            color: '#00AEEF',
            border: '1px solid #00AEEF',
        },
    },
    cancel: {
        textDecoration: 'none',
        height: '40px',
        margin: '8px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const CreateSite = () => {
    const [searchParams] = useSearchParams();
    const projectId = (searchParams && searchParams.get('projectid')) || '';
    const classes = useStyles();
    const [appStore] = useAppContext();
    const [create, { isLoading: loading }] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { data: projects } = useGetList('projects', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: { organisationId: appStore.organisationId },
    });
    const [name, setName] = React.useState();
    const [isNameValid, setIsNameValid] = React.useState(true);
    const [address, setAddress] = React.useState('');
    const [isValid, setIsValid] = React.useState(true);
    const [isLocationValid, setIsLocationValid] = React.useState(true);
    const [isProjectValid, setIsProjectValid] = React.useState(true);

    const [geolocation, setGeolocation] = React.useState({
        latitude: -36.86796905907407,
        longitude: 174.75143230389588,
    });
    const [googleMapsGeocoder, setGoogleMapsGeocoder] = React.useState({});
    const [addresses, setAddresses] = useState([]);
    const [selectedProject, setSelectedProject] = React.useState(projectId);
    const [applicationTypes, setApplicationTypes] = React.useState(applicationTypesMh);
    const [selectedSiteType, setSelectedSiteType] = React.useState(siteTypes[0].id);
    const [selectedNetworkType, setSelectedNetworkType] = React.useState(networkTypes[0].id);
    const [selectedApplicationType, setSelectedApplicationType] = React.useState(
        applicationTypes[0].id,
    );
    const [isAddressSearch, setIsAddressSearch] = React.useState(true);
    const [isMarkerChange, setIsMarkerChange] = React.useState(false);

    const { permissions } = usePermissions();
    const organisationId =
        appStore.organisationId && appStore.organisationId !== '-' ? appStore.organisationId : '';
    const canCreate =
        !!permissions &&
        permissions.includes(GROUPS.HSW_ADMIN) &&
        organisationId !== '' &&
        organisationId !== '-';

    const center = { lat: -40.9006, lng: 174.886 };
    const defaultBounds = {
        north: center.lat + 1,
        south: center.lat - 1,
        east: center.lng + 1,
        west: center.lng - 1,
    };
    const mapOptions = {
        bounds: defaultBounds,
        componentRestrictions: { country: ['nz', 'au'] },
        fields: ['address_components', 'geometry'],
        strictBounds: false,
    };

    if (!canCreate) {
        redirect('/sites');
    }

    const initMaps = React.useCallback(() => {
        const maps = window?.google?.maps;
        if (!maps) return;
        const gmGeocoder = new maps.Geocoder();
        setGoogleMapsGeocoder(gmGeocoder);
        //autocomplete.setComponentRestrictions({ country: 'nz' });
    }, []);

    React.useEffect(() => {
        if (isMarkerChange) {
            setIsMarkerChange(false);
            return;
        }
        if (!googleMapsGeocoder || !address) return;
        const geocode = () => {
            if (googleMapsGeocoder.geocode) {
                if (isAddressSearch) {
                    googleMapsGeocoder
                        .geocode({
                            address: address,
                        })
                        .then((code) => {
                            if (!code.results) return;
                            const newAddress = code.results[0];
                            const loc = newAddress.geometry.location;
                            setGeolocation({ latitude: loc.lat(), longitude: loc.lng() });
                        });
                } else {
                    const latlng = address.replace(' ', '').split(',');
                    if (latlng.length === 2 && !isNaN(latlng[0]) && !isNaN(latlng[1])) {
                        setGeolocation({ latitude: latlng[0], longitude: latlng[1] });
                    } else {
                        setGeolocation({
                            latitude: -36.86796905907407,
                            longitude: 174.75143230389588,
                        });
                    }
                }
            }
        };
        debounce(geocode(), 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, googleMapsGeocoder, isAddressSearch]);

    React.useEffect(() => {
        if (selectedSiteType === 1) {
            //MH
            setApplicationTypes(applicationTypesMh);
            setSelectedApplicationType(applicationTypesMh[0].id);
        } else if (selectedSiteType === 2 || selectedSiteType == 4) {
            // Raingarden, Groundwater
            setApplicationTypes(applicationTypesGroundwater);
            setSelectedApplicationType(applicationTypesGroundwater[0].id);
        } else if (selectedSiteType === 3) {
            // Tank
            setApplicationTypes(applicationTypesTank);
            setSelectedApplicationType(applicationTypesTank[0].id);
        } else if (selectedSiteType === 5) {
            // Raft
            setApplicationTypes(applicationTypesRaft);
            setSelectedApplicationType(applicationTypesRaft[0].id);
        }
    }, [selectedSiteType]);

    const handleMapLoad = (map) => {
        initMaps(map);
    };
    const handleAddressChange = (event) => {
        if (event.key === 'Enter') {
            setAddress(event.target.value);
        }
    };
    const handleMarkerChange = (e) => {
        if (e && e.latLng) {
            const markerSearch = () => {
                const loc = e.latLng;
                setGeolocation({ latitude: loc.lat(), longitude: loc.lng() });
                googleMapsGeocoder
                    .geocode({
                        location: {
                            lat: loc.lat(),
                            lng: loc.lng(),
                        },
                    })
                    .then((code) => {
                        if (!code || !code.results) return;
                        const newAddress = code.results[0]['formatted_address'];
                        if (newAddress) {
                            setIsMarkerChange(true);
                            if (!isAddressSearch) {
                                setAddress(loc.lat() + ', ' + loc.lng());
                            } else {
                                setAddress(newAddress);
                            }
                        }
                    });
            };
            debounce(markerSearch(), 1000);
        }
    };

    const handleCancel = () => {
        redirect('/sites');
        refresh();
    };

    const handleSaveSite = async () => {
        var valid = validate(address, 'location');
        valid = validate(name, 'name') && valid;
        valid = validate(selectedProject, 'project') && valid;
        setIsValid(valid);
        if (valid === false) return;
        const site = await create(
            'sites',
            {
                data: {
                    name: name,
                    address: address,
                    latitude: geolocation.latitude,
                    longitude: geolocation.longitude,
                    registeredDate: new Date(),
                    siteTypeId: selectedSiteType,
                    networkTypeId: selectedNetworkType,
                    applicationTypeId: selectedApplicationType,
                    projectId: selectedProject,
                },
            },
            { returnPromise: true },
        );

        const isRaftSensor = site.siteTypeId === 5;
        if (isRaftSensor) {
            const userInfo = await getUserInfo();
            const survey = {
                siteId: site.id,
                surveyTypeId: 3,
                inspectorName: userInfo.username,
                inspectorGuid: userInfo.id,
            };

            await create(
                'surveys',
                {
                    data: survey,
                },
                { returnPromise: true },
            );

            await create(
                'surveyStatus',
                {
                    data: {
                        siteId: site.id,
                        status: 2,
                        userId: userInfo.externalUserId,
                    },
                },
                { returnPromise: true },
            );
        }

        notify('Site added to project.', { type: 'success' });
        redirect('/sites');
        refresh();
    };

    const validate = (value, field) => {
        let validation = false;
        switch (field) {
            case 'location':
                validation =
                    !!value && value.length > 0 && validateText(value) && value.length < 250;
                setIsLocationValid(validation);
                break;
            case 'name':
                validation =
                    !!value && value.length > 2 && validateText(value) && value.length < 50;
                setIsNameValid(validation);
                break;
            case 'project':
                validation = !!value && value != '';
                setIsProjectValid(validation);
                break;
            default:
                validation = true;
                break;
        }
        return validation;
    };

    const handleChangeAddress = async (target) => {
        if (isAddressSearch) {
            const results = await GoogleAutoComplete(target.value, mapOptions);
            if (results && results.length > 0) {
                setAddresses(results);
            }
        }
    };

    const handleChange = (event, checkedValue) => {
        setIsAddressSearch(checkedValue);
        setAddress('');
    };

    const validText =
        (message = 'Invalid text') =>
        (value) => {
            if (value && value.length > 3) {
                if (!validateText(value)) {
                    return message;
                } else {
                    return '';
                }
            }
            return '';
        };

    return (
        <Layout>
            <Breadcrumb />
            <Typography variant="h5" gutterBottom className={classes.title}>
                Create New Site
            </Typography>
            <Grid container spacing={2} style={{ marginTop: '30px' }}>
                <Grid item xs={6}>
                    <Card style={{ minHeight: '385px' }}>
                        <Grid container spacing={2}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                width="100%"
                                ml="10px"
                                margin="8px 0px 0px 16px"
                            >
                                <Typography variant="h6" gutterBottom className={classes.heading}>
                                    Site Details
                                </Typography>
                            </Box>
                            <Grid item xs={6}>
                                <FormControl required className={classes.formControl}>
                                    <span className={classes.label}>Site Name</span>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={name}
                                        error={!isValid && !isNameValid}
                                        onChange={(event) => setName(event.target.value)}
                                        validate={[
                                            required(),
                                            minLength(2),
                                            maxLength(50),
                                            validText(),
                                        ]}
                                        helperText={
                                            !isNameValid
                                                ? 'Please enter a site name between 3 and 50 characters.'
                                                : undefined
                                        }
                                        margin="none"
                                    />
                                </FormControl>
                                <FormControl required className={classes.formControl}>
                                    <span className={classes.label}>Site Type</span>
                                    <Select
                                        id="site-type"
                                        labelId="site-type"
                                        variant="outlined"
                                        value={selectedSiteType}
                                        onChange={(event) =>
                                            setSelectedSiteType(event.target.value)
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        {siteTypes.map((siteType) => {
                                            return (
                                                <MenuItem key={siteType.id} value={siteType.id}>
                                                    {siteType.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl required className={classes.formControl}>
                                    <span className={classes.label}>Project</span>
                                    <Select
                                        id="site-project"
                                        labelId="site-project"
                                        variant="outlined"
                                        value={selectedProject}
                                        onChange={(event) => setSelectedProject(event.target.value)}
                                        error={!isValid && !isProjectValid}
                                        style={{ width: '100%' }}
                                    >
                                        {projects &&
                                            projects.map((project) => {
                                                if (!project || project.projectStatus !== 0)
                                                    return null;
                                                return (
                                                    <MenuItem key={project.id} value={project.id}>
                                                        {project?.name ?? ''}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <FormControl required className={classes.formControl}>
                                    <span className={classes.label}>Network Type</span>
                                    <Select
                                        id="site-network"
                                        labelId="site-network"
                                        variant="outlined"
                                        value={selectedNetworkType}
                                        onChange={(event) =>
                                            setSelectedNetworkType(event.target.value)
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        {networkTypes.map((networkType) => (
                                            <MenuItem key={networkType.id} value={networkType.id}>
                                                {networkType.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl required className={classes.formControl}>
                                    <span className={classes.label}>Application Type</span>
                                    <Select
                                        id="site-application"
                                        labelId="site-application"
                                        variant="outlined"
                                        value={selectedApplicationType}
                                        onChange={(event) =>
                                            setSelectedApplicationType(event.target.value)
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        {applicationTypes.map((applicationType) => {
                                            return (
                                                <MenuItem
                                                    key={applicationType.id}
                                                    value={applicationType.id}
                                                >
                                                    {applicationType.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card style={{ minHeight: '385px' }}>
                        <Grid container spacing={2}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                width="100%"
                                ml="10px"
                                margin="8px 0px 0px 16px"
                            >
                                <Typography variant="h6" gutterBottom className={classes.heading}>
                                    Site Location
                                </Typography>
                            </Box>
                            <Box width={1} ml="10px" mr="10px" marginLeft="16px">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    {!isAddressSearch && <Label sub>longitude and latitude</Label>}
                                    {isAddressSearch && <Label sub>Address lookup</Label>}
                                    <Tooltip title="Will use a different input." arrow>
                                        <Switch
                                            checked={isAddressSearch}
                                            onChange={handleChange}
                                            color="primary"
                                            name="tamper"
                                            //disabled={showMode}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Tooltip>
                                </Box>
                                {!isAddressSearch && (
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="location"
                                        type="text"
                                        placeholder="Search"
                                        variant="outlined"
                                        onKeyDown={(event) => handleAddressChange(event)}
                                        onChange={(event) =>
                                            debounce(setAddress(event.target.value), 1000)
                                        }
                                        value={address}
                                        error={!isValid && !isLocationValid}
                                        fullWidth
                                        helperText={
                                            !isLocationValid ? 'Please enter a location' : undefined
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                {isAddressSearch && (
                                    <Autocomplete
                                        options={addresses.map((option) => option.description)}
                                        onChange={(event, newValue) => {
                                            setAddress(newValue);
                                        }}
                                        value={address}
                                        autoComplete={false}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Site Address"
                                                name="siteAddress"
                                                onChange={(event) => {
                                                    debounce(
                                                        handleChangeAddress(event.target),
                                                        1000,
                                                    );
                                                }}
                                                variant="outlined"
                                                error={!isValid && !isLocationValid}
                                                helperText={
                                                    !isLocationValid
                                                        ? 'Please enter a location'
                                                        : undefined
                                                }
                                            />
                                        )}
                                        isOptionEqualToValue={() => true}
                                    />
                                )}
                                <br></br>
                                <br></br>
                                <Box className={classes.mapContainer}>
                                    <Map
                                        center={{
                                            lat: +geolocation.latitude,
                                            lng: +geolocation.longitude,
                                        }}
                                        zoom={10}
                                        onLoad={(map) => handleMapLoad(map)}
                                        lib={lib}
                                        withLocationMem={false}
                                    >
                                        <Marker
                                            position={{
                                                lat: +geolocation.latitude,
                                                lng: +geolocation.longitude,
                                            }}
                                            onDragEnd={(e) => handleMarkerChange(e)}
                                            draggable
                                        />
                                    </Map>
                                </Box>
                            </Box>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Box mt={3} display="flex">
                <AsyncButton
                    onClick={handleSaveSite}
                    timeout={10000}
                    disabled={loading}
                    color="primary"
                    className={classes.submit}
                >
                    Save
                </AsyncButton>
                <Button className={classes.cancel} onClick={() => handleCancel()}>
                    Cancel
                </Button>
            </Box>
        </Layout>
    );
};

export default CreateSite;
