import React from 'react';
import { useMediaQuery, Badge, Box } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
    MenuItemLink,
    useResourceDefinitions,
    useSidebarState,
    useGetPermissions,
    useLogout,
    useGetOne,
    Logout,
    Menu,
} from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import OrganisationSelector from './OrganisationSelector';
import { ReactComponent as MainLogo } from '../img/hynds-aquanect-logo.svg';
import { ReactComponent as LogoSquare } from '../img/hynds-aquanect-icon.svg';
import { makeStyles } from '@mui/styles';
import { ReactComponent as HomeIcon } from '../icons/home.svg';
import ToggleButton from './ToggleButton';
import GROUPS from '../auth/groups';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { useAppContext } from '../context/AppContext';
import { useFeature } from '../context/FeatureFlags';
import { Features } from '../constant';
import { getUserInfo, getAccessToken } from '../utils/authProvider';

const useStyles = makeStyles(() => ({
    customerLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: '8px',
        marginLeft: '15px',
    },
}));

const MyMenu = () => {
    const classes = useStyles();
    const [permissions, setPermissions] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState();
    const getPermissions = useGetPermissions();
    const handleLogout = useLogout();
    const [appStore] = useAppContext();
    const hasAlarms = useFeature(Features.alarms);
    const { data: alarmscount } = useGetOne('alarmscount', {
        id: appStore.organisationId != '' ? appStore.organisationId : '-',
    });
    const [alarmCount, setAlarmCount] = React.useState(0);
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(
        (name) => resourcesDefinitions[`${name}`],
    );

    React.useEffect(() => {
        setAlarmCount(alarmscount?.unacknowledged ?? 0);
    }, [alarmscount]);

    React.useEffect(() => {
        const getUser = async () => {
            return await getUserInfo();
        };

        getUser().then((user) => {
            if (user) setUserInfo(user);
        });

        getPermissions().then((permissions) => setPermissions(permissions));
    }, [getPermissions]);

    React.useEffect(() => {
        const getAlarmCount = async () => {
            const token = await getAccessToken();
            const organisationId = appStore.organisationId != '' ? appStore.organisationId : '-';
            let status;
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/alarmscount/${organisationId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            })
                .then((response) => {
                    status = response.status;
                    return response.json();
                })
                .then((data) => {
                    if (data) setAlarmCount(data?.unacknowledged ?? 0);
                })
                .catch((error) => {
                    console.log(error);
                });
            if (status === 404) {
                window.sessionStorage.clear();
                handleLogout();
            }
        };
        getAlarmCount();
    }, [appStore.organisationId, handleLogout]);

    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [open, setOpen] = useSidebarState();

    const adminLevel = hasAlarms ? ['alarms'] : [];

    const onToggleSideBar = () => setOpen(!open);

    return (
        <Menu
            sx={{
                color: '#4f575e',
                height: '100%',
                marginTop: 0,
                paddingTop: 0,
            }}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
                height="103px"
            >
                {open ? <MainLogo width={220} /> : <LogoSquare width={60} />}

                <ToggleButton open={open} onClick={onToggleSideBar} />
            </Box>

            {open && <span className={classes.customerLabel}>Customer</span>}
            <Box>
                <OrganisationSelector
                    open={open}
                    canEdit={
                        permissions.includes(GROUPS.HSW_ADMIN) ||
                        permissions.includes(GROUPS.HSW_USER)
                    }
                />
            </Box>
            <MenuItemLink to="/" primaryText="Dashboard" leftIcon={<HomeIcon />} />
            {resources
                .filter((resource) => resource.name === 'projects')
                .map((resource) => {
                    if (
                        permissions.includes(GROUPS.HSW_ADMIN) ||
                        permissions.includes(GROUPS.HSW_USER) ||
                        permissions.includes(GROUPS.CUSTOMER_ADMIN)
                    )
                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={
                                    (resource.options && resource.options.label) || resource.name
                                }
                                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            />
                        );
                })}

            {resources
                .filter((resource) => resource.name === 'sites')
                .map((resource) => {
                    return (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={
                                (resource.options && resource.options.label) || resource.name
                            }
                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                        />
                    );
                })}

            {resources
                .filter((resource) => resource.name === 'devices')
                .map((resource) => {
                    if (permissions.includes(GROUPS.HSW_ADMIN))
                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={
                                    (resource.options && resource.options.label) || resource.name
                                }
                                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            />
                        );
                })}

            {resources
                .filter((resource) => resource.name === 'organisations')
                .map((resource) => {
                    if (
                        permissions.includes(GROUPS.HSW_ADMIN) ||
                        permissions.includes(GROUPS.HSW_USER)
                    )
                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={
                                    (resource.options && resource.options.label) || resource.name
                                }
                                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            />
                        );
                })}

            {resources
                .filter((resource) => resource.name === 'users')
                .map((resource) => {
                    if (
                        permissions.includes(GROUPS.HSW_ADMIN) ||
                        permissions.includes(GROUPS.HSW_USER) ||
                        permissions.includes(GROUPS.CUSTOMER_ADMIN)
                    )
                        return (
                            <MenuItemLink
                                key={'menu-users'}
                                to={`/${resource.name}`}
                                primaryText={
                                    (resource.options && resource.options.label) || resource.name
                                }
                                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            />
                        );
                })}

            {(alarmCount === undefined || alarmCount === 0) &&
                resources
                    .filter((resource) => adminLevel.includes(resource.name))
                    .map((resource) => (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}?filter=${JSON.stringify({ alarmStatus: 1 })}`}
                            primaryText={
                                (resource.options && resource.options.label) || resource.name
                            }
                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                        />
                    ))}

            {alarmCount > 0 &&
                resources
                    .filter((resource) => adminLevel.includes(resource.name))
                    .map((resource) => (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}?filter=${JSON.stringify({ alarmStatus: 1 })}`}
                            primaryText={
                                (resource.options && resource.options.label) || resource.name
                            }
                            leftIcon={
                                resource.icon && (
                                    <Badge
                                        badgeContent={alarmCount}
                                        color="error"
                                        max={9}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <resource.icon />
                                    </Badge>
                                )
                            }
                        />
                    ))}

            {isXSmall && <Logout />}
            <Box id="menu-bottom" marginTop="auto">
                {userInfo && (
                    <MenuItemLink
                        key="user-profile"
                        to={'/'}
                        primaryText={open ? userInfo.name : ''}
                        leftIcon={
                            <PermIdentityIcon
                                style={{
                                    color: '#fff',
                                    verticalAlign: 'bottom',
                                    display: 'inline-block',
                                }}
                            />
                        }
                        disabled
                        style={{
                            display: 'inline-block',
                            maxWidth: '220px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            paddingTop: '11px',
                            color: 'unset',
                            opacity: 'unset',
                            fontWeight: 'bold',
                        }}
                    />
                )}

                <MenuItemLink
                    key="logout"
                    to={'/#'}
                    primaryText={open ? 'Logout' : ''}
                    leftIcon={<ExitIcon style={{ color: '#fff' }} />}
                    onClick={() => {
                        window.sessionStorage.clear();
                        handleLogout();
                    }}
                    style={{ width: open ? '238px' : '55px' }}
                />
            </Box>
        </Menu>
    );
};

export default MyMenu;
