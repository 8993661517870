import React from 'react';
import { Box, Typography, Button, Link, Container } from '@mui/material';
import { contactLink } from '../constant';
import { useLogout } from 'react-admin';

const LoginError = ({ details = null }) => {
    const logout = useLogout();
    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                textAlign="left"
            >
                <Typography variant="h4" gutterBottom>
                    Authentication Failed
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Your login attempt was unsuccessful. Please check your credentials and try
                    again. If the problem persists, contact the{' '}
                    <Link href={contactLink}>system administrator</Link> for assistance.
                </Typography>
                {details && (
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Error details: {details}
                    </Typography>
                )}

                <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => logout()}>
                    Back to Login
                </Button>
            </Box>
        </Container>
    );
};

export default LoginError;
