import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth0, validateAccessToken } from './auth0Service';
import { Loading, ThemeProvider } from 'react-admin';
import Theme from '../theme/index';
import { createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import LoginError from './LoginError';
import { validateExternalUser } from '../utils/authProvider';

function LoginCallback() {
    const [isLoadingToken, setIsLoadingToken] = useState(true);
    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [claims, setClaims] = useState(null);
    const [isValidating, setIsValidating] = useState(true);
    const hasFetched = useRef(false); // Tracks if the request has run

    // Handle redirect
    useEffect(() => {
        const handleCallback = async () => {
            try {
                await auth0.handleRedirectCallback();
                const access_token = await auth0.getTokenSilently();
                const idTokenClaims = await auth0.getIdTokenClaims();
                setAccessToken(access_token);
                setClaims(idTokenClaims);
            } catch (error) {
                if (error.message === 'There are no query params available for parsing.') {
                    setError(
                        new Error(
                            "It looks like you've accessed this page incorrectly. Please start the login process from the application's sign-in page.",
                        ),
                    );
                } else if (error.message === 'Access denied. User has no assigned roles.') {
                    setError(
                        new Error(
                            "You don't have permission to access this application. Your account may have been removed.",
                        ),
                    );
                } else {
                    setError(error);
                }
            } finally {
                setIsLoadingToken(false);
            }
        };
        if (!hasFetched.current) {
            hasFetched.current = true;
            handleCallback();
        }
    }, []);

    // Run validations
    useEffect(() => {
        if (error || isLoadingToken || !hasFetched.current) return;

        setIsValidating(true);
        if (!claims) {
            setError(Error('Unable to get user claims'));
            return;
        }
        if (claims.email_verified !== true) {
            setError(Error('Email is not verified'));
            return;
        }

        if (!accessToken) {
            setError(Error('Unable to get access token'));
            return;
        }

        const asyncValidations = async () => {
            try {
                await validateAccessToken(accessToken);
                localStorage.setItem('hsw_auth', accessToken);
                localStorage.setItem('hsw_isAzureAD', false);
                await validateExternalUser();
            } catch (error) {
                setError(error);
            } finally {
                setIsValidating(false);
            }
        };

        asyncValidations();
    }, [error, isLoadingToken, accessToken, claims]);

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box height="100vh" pt={10}>
                {error && <LoginError details={!!error && error.message} />}
                {!error && (isLoadingToken || isValidating) && <Loading />}
                {!error && !isLoadingToken && !isValidating && <Navigate to="/" replace={true} />}
            </Box>
        </ThemeProvider>
    );
}

export default LoginCallback;
