import { useEffect, useState } from 'react';
import { getAccessToken } from '../../utils/authProvider';

export default function useRaftLatest(siteId) {
    const [isLoading, setIsLoading] = useState(siteId ? true : false);
    const [error, setError] = useState(null);

    const [latestReading, setLatestReading] = useState(null);

    useEffect(() => {
        if (!siteId) return;

        getData();
        async function getData() {
            setIsLoading(true);
            const token = await getAccessToken();
            const result = await fetchData(token, siteId);
            if (result.error) {
                setError(result.error);
                setLatestReading(null);
            } else {
                setError(null);
                setLatestReading(result.latestReading);
            }
            setIsLoading(false);
        }
    }, [siteId]);

    return {
        data: latestReading,
        isLoading,
        error,
    };
}

async function fetchData(token, siteId) {
    let result;
    const url = `${process.env.REACT_APP_API_BASE_URL}/analytics-raft/latest/${siteId}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ?? '',
            },
        });
        if (response.status === 200) {
            const payload = await response.json();
            result = {
                latestReading: payload.latestReading,
            };
        } else {
            result = {
                error: `HTTP error status: ${response.status}`,
            };
        }

        result.status = response.status;
    } catch (error) {
        result = {
            error: 'Fetch error',
        };
    }

    return result;
}
