import React from 'react';
import { Admin, CustomRoutes } from 'react-admin';
import dataProvider from './utils/dataProvider';
import { BrowserRouter, Route } from 'react-router-dom';
import Dashboard, { CatchAll } from './dashboard';

import ConfirmSignup from './auth/ConfirmSignup';
import AzureADCallback from './auth/azureADCallback';
import PortalLayout from './Layout';
import AppProvider from './context/AppContext';
import Theme from './theme';

import LoginPage from './auth/LoginPage';
import authProvider from './utils/authProvider';
import { FeatureProvider } from './context/FeatureFlags';
import { Features } from './constant';
import Resources from './Resources';
import { QueryClient } from 'react-query';
import LoginCallback from './auth/LoginCallback';
import PasswordResetCallback from './auth/PasswordResetCallback';

const getFeatures = (feat) => feat.replaceAll(' ', '').split(',');
const features = process.env.REACT_APP_FEATURES
    ? getFeatures(process.env.REACT_APP_FEATURES)
    : Object.keys(Features);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    return (
        <AppProvider>
            <FeatureProvider features={features}>
                <BrowserRouter
                    future={{
                        v7_startTransition: true,
                        v7_relativeSplatPath: false,
                    }}
                >
                    <Admin
                        layout={PortalLayout}
                        dataProvider={dataProvider}
                        dashboard={Dashboard}
                        authProvider={authProvider}
                        disableTelemetry
                        loginPage={LoginPage}
                        title="Aquanect"
                        theme={Theme}
                        catchAll={CatchAll}
                        queryClient={queryClient}
                    >
                        <CustomRoutes noLayout>
                            <Route
                                key="confirm"
                                path="/confirm-signup"
                                element={<ConfirmSignup />}
                            />
                            <Route
                                key="azureADCallback"
                                path="/azure-ad-callback"
                                element={<AzureADCallback />}
                            />
                            <Route
                                key="loginCallback"
                                path="/login-callback"
                                element={<LoginCallback />}
                            />
                            <Route
                                key="passwordResetCallback"
                                path="/password-reset-callback"
                                element={<PasswordResetCallback />}
                            />
                        </CustomRoutes>
                        {Resources}
                    </Admin>
                </BrowserRouter>
            </FeatureProvider>
        </AppProvider>
    );
}

export default App;
