import React from 'react';
import { Box, Typography, Button, Link, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { contactLink } from '../constant';

const PasswordResetError = ({ details = null }) => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                textAlign="left"
            >
                <Typography variant="h4" gutterBottom>
                    Password reset failed
                </Typography>

                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Your reset password attempt was unsuccessful. Please try again. If the problem
                    persists, contact the <Link href={contactLink}>system administrator</Link> for
                    assistance.
                </Typography>

                {details && (
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {` Error details: ${details}`}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => navigate('/login')}
                >
                    Back to Login
                </Button>
            </Box>
        </Container>
    );
};

export default PasswordResetError;
