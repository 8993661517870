import React, { createContext, useEffect, useContext, useState } from 'react';
import { getTime } from 'date-fns';
import { getAccessToken } from '../../../utils/authProvider';

const MiscDataContext = createContext({});

const MiscDataContextProvider = ({ children, deviceId, from, to }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const update = async () => {
            setIsLoading(true);
            const fromTimestamp = getTime(from);
            const toTimestamp = getTime(to);
            if (!fromTimestamp || !toTimestamp || fromTimestamp > toTimestamp) return;

            const { data: miscData, error: errorFetchMisc } = await fetchMiscData(
                deviceId,
                fromTimestamp,
                toTimestamp,
            );
            setData(miscData);
            setError(errorFetchMisc);
            setIsLoading(false);
        };

        if (deviceId && from && to) {
            update();
        }
    }, [deviceId, from, to]);

    return (
        <MiscDataContext.Provider value={{ data, from, to, isLoading, error }}>
            {children}
        </MiscDataContext.Provider>
    );
};

const useMiscDataContext = () => {
    const context = useContext(MiscDataContext);
    if (!context) {
        throw new Error('useMiscDataContext must be used within a MiscDataContextProvider');
    }
    return context;
};

const fetchMiscData = async (deviceId, fromDate, toDate) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/miscdata?fromDate=${fromDate}&toDate=${toDate}`;
    const options = {
        method: 'GET',
    };
    let error;
    let data;

    const token = await getAccessToken();
    if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
    options.headers.append('Authorization', token);
    options.headers.append('Accept', 'application/json');
    try {
        const res = await fetch(url, options);
        const json = await res.json();
        data = parseReadings(json.readings);
    } catch (err) {
        error = err;
    }

    return {
        data,
        error,
    };
};

const parseReadings = (readings) => {
    if (!readings || readings.length === 0) {
        return [];
    }
    const result = readings.reduce(
        (prev, curr) => {
            prev.battery.push([curr.timestamp, curr.battery]);
            prev.humidity.push([curr.timestamp, curr.humidity]);
            prev.temperature.push([curr.timestamp, curr.temperature]);
            return prev;
        },
        {
            battery: [],
            humidity: [],
            temperature: [],
        },
    );
    return result;
};

export { useMiscDataContext, MiscDataContextProvider };
