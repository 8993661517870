import { useState, useEffect } from 'react';
import { getTime } from 'date-fns';
import { getAccessToken } from '../../../utils/authProvider';

const useFetchRainfallData = (from, to, siteId) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const encodedSiteId = encodeURIComponent(siteId);

    useEffect(() => {
        if (!from || !to || !siteId) return;

        const fetchData = async () => {
            setLoading(true);
            setError(null);

            const fromTimestamp = getTime(from);
            const toTimestamp = getTime(to);

            try {
                const token = await getAccessToken();
                const response = await fetch(
                    process.env.REACT_APP_API_BASE_URL +
                        `/rainfall-data/${encodedSiteId}?fromDate=${fromTimestamp}&toDate=${toTimestamp}`,
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    },
                );

                if (response.status === 200) {
                    const result = await response.json();
                    setData(result);
                } else {
                    throw new Error(`HTTP error status: ${response.status}`);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [from, to, siteId, encodedSiteId]);

    return { data, loading, error };
};

export default useFetchRainfallData;
