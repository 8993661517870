import React, { createContext, useEffect, useContext, useState } from 'react';
import { getTime } from 'date-fns';
import { getAccessToken } from '../../../utils/authProvider';

const TamperingDataContext = createContext({});

const TamperingDataContextProvider = ({ children, deviceId, from, to }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const update = async () => {
            setIsLoading(true);
            setError(undefined);
            const fromTimestamp = getTime(from);
            const toTimestamp = getTime(to);
            if (!fromTimestamp || !toTimestamp || fromTimestamp > toTimestamp) return;

            const { data: tamperingData, error: errorFetchTampering } = await fetchData(
                deviceId,
                fromTimestamp,
                toTimestamp,
            );
            setData(tamperingData);
            setError(errorFetchTampering);
            setIsLoading(false);
        };

        if (deviceId && from && to) {
            update();
        }
    }, [deviceId, from, to]);

    return (
        <TamperingDataContext.Provider value={{ data, from, to, isLoading, error }}>
            {children}
        </TamperingDataContext.Provider>
    );
};

const useTamperingDataContext = () => {
    const context = useContext(TamperingDataContext);
    if (!context) {
        throw new Error(
            'useTamperingDataContext must be used within a TamperingDataContextProvider',
        );
    }
    return context;
};

const fetchData = async (deviceId, fromDate, toDate) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/tampering?fromDate=${fromDate}&toDate=${toDate}`;

    const options = {
        method: 'GET',
    };
    let error;
    let data;

    const token = await getAccessToken();
    if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
    options.headers.append('Authorization', token);
    options.headers.append('Accept', 'application/json');
    try {
        const res = await fetch(url, options);
        const json = await res.json();
        if (res.status !== 200) {
            throw Error(JSON.stringify(json));
        }

        data = parseReadings(json.readings);
    } catch (err) {
        error = err;
    }

    return {
        data,
        error,
    };
};

const parseReadings = (readings) => {
    if (!readings || readings.length === 0) {
        return [];
    }
    const result = [];
    const parsed = readings.map(({ timestamp, movement_alarm }) => [timestamp, movement_alarm]);
    for (let index = 0; index < parsed.length; index++) {
        const element = parsed[`${index}`];
        result.push(element);
    }
    return result;
};

export { useTamperingDataContext, TamperingDataContextProvider };
