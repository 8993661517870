import { useEffect, useState } from 'react';
import { getAccessToken } from '../../utils/authProvider';

export default function useGpsDeviceData(deviceId, fromDate, toDate) {
    const [isLoading, setIsLoading] = useState(deviceId && fromDate && toDate ? true : false);
    const [error, setError] = useState(null);

    const [readings, setReadings] = useState(null);
    const [nextFrom, setNextFrom] = useState(null);

    useEffect(() => {
        if (!deviceId || !fromDate || !toDate) return;

        getData();
        async function getData() {
            setIsLoading(true);
            const tsFrom = fromDate.getTime();
            const tsTo = toDate.getTime();
            const token = await getAccessToken();
            const attributes = ['lat', 'long'];
            const result = await fetchData(token, deviceId, tsFrom, tsTo, attributes);
            if (result.error) {
                setError(result.error);
                setReadings(null);
                setNextFrom(null);
            } else {
                setError(null);
                setReadings(result.readings);
                setNextFrom(result.nextFromDate);
            }
            setIsLoading(false);
        }
    }, [deviceId, fromDate, toDate]);

    // Add readings based on pagination
    useEffect(() => {
        if (nextFrom) {
            getData();
        }

        async function getData() {
            setIsLoading(true);
            const tsFrom = fromDate.getTime();
            const tsTo = toDate.getTime();
            const token = await getAccessToken();
            const attributes = ['lat', 'long'];
            const result = await fetchData(token, deviceId, tsFrom, tsTo, attributes, nextFrom);
            if (result.error) {
                setError(result.error);
                setReadings(null);
                setNextFrom(null);
            } else {
                setError(null);
                setReadings((rds) => [...rds, ...result.readings]);
                setNextFrom(result.nextFromDate);
            }
            setIsLoading(false);
        }
    }, [deviceId, nextFrom, fromDate, toDate]);

    return {
        data: readings,
        isLoading,
        error,
    };
}

async function fetchData(token, deviceId, from, to, attributes, nextFrom) {
    let error;
    let readings;
    let nextFromDate;

    const params = new URLSearchParams({ fromDate: from, toDate: to, attributes });
    if (nextFrom) params.append('nextFromDate', nextFrom);
    const url = `${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/gpsdata?${params}`;
    const options = {
        method: 'GET',
        headers: new Headers({
            Accept: 'application/json',
            Authorization: token,
        }),
    };

    try {
        const res = await fetch(url, options);
        const json = await res.json();
        if (res.status !== 200) {
            error = Error(JSON.stringify(json));
        } else {
            (readings = json.readings), (nextFromDate = json.nextFromDate);
        }
    } catch (err) {
        error = err;
    }

    return {
        readings,
        nextFromDate,
        error,
    };
}
