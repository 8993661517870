import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';
import {
    Create,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    useNotify,
    usePermissions,
    useRedirect,
    useCreate,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { surveyTypes } from '.';
import { getUserInfo } from '../utils/authProvider';
import Layout from '../components/Layout';
import { useAppContext } from '../context/AppContext';
import GROUPS from '../auth/groups';
import MeasurementsInput from './components/MeasurementsInput';
import QuestionsInput from './components/QuestionsInput';
import CreateToolBar from './components/CreateToolBar';

const SurveyCreate = () => {
    const [appStore] = useAppContext();
    const [create, { isLoading: isLoadingSurveyStatus }] = useCreate();
    const [user, setUser] = useState(null);
    const { permissions } = usePermissions();
    const location = useLocation();
    const notify = useNotify();
    const redirect = useRedirect();

    const transform = (data) => {
        let inspectorGuid = data?.inspectorGuid || user?.id;
        return {
            ...data,
            inspectorGuid,
        };
    };

    const { state } = location;
    let record = state?.record;
    let approveSurvey = state?.approveSurvey;

    const organisationId = appStore.organisationId !== '-' ? appStore.organisationId : null;

    const closeAndRedirect = useCallback(() => {
        if (record?.siteId !== null) redirect('show', 'sites', record.siteId);
        else redirect('list', 'sites');
    }, [record, redirect]);

    const onSucessSurveyStatus = () => {
        notify('Site status updated', { type: 'success' });
        closeAndRedirect();
    };

    const onErrorSurveyStatus = () => {
        notify('Site status not updated', { type: 'warning' });
        closeAndRedirect();
    };

    const onSuccessSurvey = async (data) => {
        if (!approveSurvey) {
            notify('Survey saved.', { type: 'success' });
            closeAndRedirect();
        } else if (data?.siteId && user?.externalUserId) {
            notify('Survey saved. Updating site status...');

            await create(
                'surveyStatus',
                {
                    data: {
                        siteId: data.siteId,
                        status: 2,
                        userId: user.externalUserId,
                    },
                },
                {
                    returnPromise: true,
                    onSuccess: onSucessSurveyStatus,
                    onError: onErrorSurveyStatus,
                },
            );
        } else {
            notify('Survey saved. Unable to update site status');
            closeAndRedirect();
        }
    };

    const onErrorSurvey = () => {
        notify('Survey not saved', { type: 'warning' });
        closeAndRedirect();
    };

    useEffect(() => {
        async function getUser() {
            const user = await getUserInfo();
            if (user) {
                setUser(user);
            } else {
                notify('Unable to fetch user', { type: 'warning' });
                closeAndRedirect();
            }
        }
        getUser();
    }, [notify, closeAndRedirect]);

    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }

    return (
        <Layout title="Create New Survey">
            <Create
                redirect={false}
                mutationOptions={{ onSuccess: onSuccessSurvey, onError: onErrorSurvey }}
                component="div"
                transform={transform}
            >
                <SimpleForm
                    display="flex"
                    columnGap="20px"
                    flexDirection="row"
                    alignItems="stretch"
                    defaultValue={record || null}
                    toolbar={<CreateToolBar disabled={isLoadingSurveyStatus || !user} />}
                >
                    <Card
                        sx={{
                            flexBasis: '30%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '25px',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Basic information
                        </Typography>
                        <ReferenceInput
                            source="siteId"
                            reference="sites"
                            validate={[required()]}
                            perPage={100}
                            filter={organisationId ? { organisationId } : null}
                        >
                            <SelectInput
                                label="Site"
                                optionText="name"
                                disabled={!!record}
                                variant="outlined"
                            />
                        </ReferenceInput>
                        <TextInput
                            source="address"
                            disabled={!!record}
                            validate={[required()]}
                            variant="outlined"
                        />
                        <NumberInput
                            source="latitude"
                            disabled={!!record}
                            validate={[required()]}
                            variant="outlined"
                        />
                        <NumberInput
                            source="longitude"
                            disabled={!!record}
                            validate={[required()]}
                            variant="outlined"
                        />
                        <TextInput
                            source="inspectorName"
                            disabled={true}
                            validate={[required()]}
                            defaultValue={user?.name}
                            variant="outlined"
                        />

                        <SelectInput
                            label="Survey Type"
                            optionText="name"
                            choices={surveyTypes}
                            source="surveyTypeId"
                            disabled={!!record}
                            validate={[required()]}
                            variant="outlined"
                        />
                    </Card>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '25px',
                            flex: 1,
                            justifyContent: 'stretch',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Measurements & Questions
                        </Typography>
                        <Box flex={1} display="flex" justifyContent="stretch" padding="30px 0px">
                            <MeasurementsInput />
                        </Box>
                        <Divider flexItem={true} />
                        <Box padding="30px 0px">
                            <QuestionsInput />
                        </Box>
                    </Card>
                </SimpleForm>
            </Create>
        </Layout>
    );
};

export default SurveyCreate;
