import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'react-admin';
import Theme from '../theme/index';
import { createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import PasswordResetError from './PasswordResetError';

function PasswordResetCallback() {
    // Query parameters
    const [searchParams] = useSearchParams();
    const success = searchParams.get('success');
    const message = searchParams.get('message');

    let customMessage = null;
    if (success !== true && message === 'This URL can be used only once') {
        customMessage =
            'This password reset link has already been used or has expired. Please request a new reset link to continue.';
    }
    if (success !== true && message === 'Access expired.') {
        customMessage =
            'Your password reset link has expired. Please request a new one to continue.';
    }

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box height="100vh" pt={10}>
                {success === 'true' && <Navigate to="/login" replace={true} />}
                {success !== 'true' && <PasswordResetError details={customMessage ?? message} />}
            </Box>
        </ThemeProvider>
    );
}

export default PasswordResetCallback;
