import React, { createContext, useEffect, useContext, useState } from 'react';
import { getTime } from 'date-fns';
import { getAccessToken } from '../../utils/authProvider';

const LqiDataContext = createContext({});

const LqiDataContextProvider = ({ children, deviceId, from, to, createdAt }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const update = async () => {
            setIsLoading(true);
            setError(undefined);
            const fromTimestamp = getTime(from);
            const toTimestamp = getTime(to);
            const createdTimestamp = getTime(new Date(createdAt));
            if (!fromTimestamp || !toTimestamp || fromTimestamp > toTimestamp) return;

            const { data: lqiData, error: errorFetchLqi } = await fetchLqiData(
                deviceId,
                fromTimestamp,
                toTimestamp,
                createdTimestamp,
            );
            setData(lqiData);
            setError(errorFetchLqi);
            setIsLoading(false);
        };

        if (deviceId && from && to) {
            update();
        }
    }, [deviceId, from, to, createdAt]);

    return (
        <LqiDataContext.Provider value={{ data, from, to, isLoading, error }}>
            {children}
        </LqiDataContext.Provider>
    );
};

const useLqiDataContext = () => {
    const context = useContext(LqiDataContext);
    if (!context) {
        throw new Error('useLqiDataContext must be used within a LqiDataContextProvider');
    }
    return context;
};

const fetchLqiData = async (deviceId, fromDate, toDate, createdDate) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/lqidata?fromDate=${fromDate}&toDate=${toDate}&createdDate=${createdDate}`;
    const options = {
        method: 'GET',
    };
    let error;
    let data;

    const token = await getAccessToken();
    if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
    options.headers.append('Authorization', token);
    options.headers.append('Accept', 'application/json');
    try {
        const res = await fetch(url, options);
        const json = await res.json();
        if (res.status !== 200) {
            throw Error(JSON.stringify(json));
        }
        data = parseReadings(json.readings);
    } catch (err) {
        error = err;
    }

    return {
        data,
        error,
    };
};

const parseReadings = (readings) => {
    if (!readings || readings.length === 0) {
        return [];
    }
    const result = readings.map(({ timestamp, lqi }) => [timestamp, lqi]);
    return result;
};

export { useLqiDataContext, LqiDataContextProvider };
