import React from 'react';
import { getAccessToken } from '../utils/authProvider';

const useFetch = (url, options) => {
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const token = await getAccessToken();
            if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
            options.headers.append('Authorization', token);
            options.headers.append('Accept', 'application/json');
            try {
                const res = await fetch(url, options);
                const json = await res.json();
                setResponse(json.data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { response, error, isLoading };
};

export default useFetch;
