import { queryClient } from '../App';
import {
    auth0,
    auth0_logout_uri,
    checkAuth_Auth0,
    getAccessTokenAuth0,
    getUserIdAuth0,
    mapUserInfoAuth0,
} from '../auth/auth0Service';
import {
    checkAuth_AzureAD,
    getAccessTokenAzureAD,
    getUserIdAzureAD,
    logoutAzureAD,
    mapUserInfoAzureAD,
} from '../auth/azureADService';
import { redirect } from 'react-router-dom';

const isAzureADToken = () => {
    return localStorage.getItem('hsw_isAzureAD')?.toLowerCase() === 'true';
};

const login = async () => {
    redirect('/login');
};

const getPermissions = async () => {
    try {
        const access_token = localStorage.getItem('hsw_auth');
        if (!access_token) {
            return Promise.resolve('guest');
        }
        const userInfo = await getUserInfo();
        return userInfo.roles ? Promise.resolve(userInfo.roles) : Promise.resolve('guest');
    } catch (error) {
        console.error(error);
        return Promise.resolve('guest');
    }
};

const checkAuth = async () => {
    const result = isAzureADToken() ? await checkAuth_AzureAD() : await checkAuth_Auth0();
    if (result !== true) {
        throw Error('User not authenticated');
    }
};

const logout = async () => {
    const isAzureUser = isAzureADToken();
    localStorage.removeItem('hsw_auth');
    localStorage.removeItem('app');
    localStorage.removeItem('hsw_isAzureAD');
    sessionStorage.removeItem('hsw_user');

    // Legacy. Remove token from old sessions
    const refresh_token = localStorage.getItem('hsw_token');
    if (refresh_token) {
        localStorage.removeItem('hsw_token');
    }

    if (isAzureUser) {
        await logoutAzureAD();
    } else {
        await auth0.logout({
            logoutParams: {
                returnTo: `${auth0_logout_uri}`,
            },
        });
        return false; // Return false to avoid React Admin redirect. Redirect is already done by Auth0 Logout
    }
};

export const getAccessToken = async () => {
    let token = null;
    token = isAzureADToken() ? await getAccessTokenAzureAD() : await getAccessTokenAuth0();

    if (!token) {
        return logout();
    } else {
        queryClient.invalidateQueries();
        return 'Bearer ' + token;
    }
};

export async function listRolesForUser(userId) {
    const url = process.env.REACT_APP_API_BASE_URL + `/userauth/${userId}`;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await getAccessToken(),
        },
    };
    const res = await fetch(url, options);

    return res;
}

export const getUserInfo = async () => {
    try {
        const bearer_token = await getAccessToken();
        if (!bearer_token) {
            return {};
        }
        const isAzureUser = isAzureADToken();
        let hsw_user = sessionStorage.getItem('hsw_user');
        if (!hsw_user) {
            const access_token = bearer_token.replace('Bearer ', '');
            const userId = isAzureUser
                ? getUserIdAzureAD(access_token)
                : getUserIdAuth0(access_token);
            const response = await fetch(
                process.env.REACT_APP_API_BASE_URL + `/users/username/${userId}`,
                {
                    method: 'GET',
                    headers: { Authorization: bearer_token },
                },
            );
            if (!response.ok) {
                return {};
            }
            const user = await response.json();
            hsw_user = JSON.stringify(user);
            sessionStorage.setItem('hsw_user', hsw_user);
        }
        const user = JSON.parse(hsw_user);
        const userInfo = isAzureUser
            ? mapUserInfoAzureAD(bearer_token, user)
            : mapUserInfoAuth0(bearer_token, user);
        return userInfo;
    } catch (e) {
        await authProvider.logout();
        return '';
    }
};

export async function addRolesToUser(userId, roleNames) {
    const url = process.env.REACT_APP_API_BASE_URL + `/userauth/${userId}`;
    const body = { roles: roleNames };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken(),
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

export async function removeRolesFromUser(userId, roleNames) {
    const url = process.env.REACT_APP_API_BASE_URL + `/userauth/${userId}`;
    const body = { roles: roleNames };
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken(),
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

export async function forgotPassword(email) {
    const url = process.env.REACT_APP_API_BASE_URL + '/users/forgotPassword';
    const body = {
        email,
        isMobile: false,
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    const res = await fetch(url, options);
    const json = await res.json();

    return { status: res.status, data: json };
}

export async function forcePasswordReset(userId) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/users/forcePasswordReset/${userId}`;
    const options = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken(),
        },
    };

    const res = await fetch(url, options);
    const json = await res.json();

    return { status: res.status, data: json };
}

export async function resetPassword(email, code, password) {
    const url = process.env.REACT_APP_API_BASE_URL + '/users/resetPassword';
    const body = {
        email: email,
        code: code,
        password: password,
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

export const validateExternalUser = async () => {
    const userInfo = await getUserInfo();
    if (!userInfo || !userInfo.externalUserId) {
        throw Error('Cannot fetch user information');
    }
};

const authProvider = {
    login,
    checkError: () => Promise.resolve(),
    checkAuth,
    logout,
    getIdentity: () => Promise.resolve(),
    handleCallback: () => Promise.resolve(),
    getPermissions,
};

export default authProvider;
