import { useState, useEffect, useCallback } from 'react';
import { getAccessToken } from '../../utils/authProvider';
import { fetchUtils } from 'react-admin';

function useSitesLevel(sites, fromDate, toDate) {
    const [data, setData] = useState(() => {
        if (sites) {
            return sites.map((site) => ({
                ...site,
                readings: [],
                loading: true,
                error: null,
            }));
        }
        return null;
    });

    // Recursive function to get paginated readings and update state.
    // Function catches API errors and udpate the state
    const getSiteData = useCallback(async (site, fromTimestamp, toTimestamp) => {
        try {
            const { readings, nextFromDate } = await fetchData(site.id, fromTimestamp, toTimestamp);
            const loading = !!nextFromDate;
            setData((data) => updateData(data, site.id, loading, false, readings));
            if (loading) {
                await getSiteData(site, nextFromDate, toTimestamp);
            }
        } catch (error) {
            setData((data) => updateData(data, site.id, false, true, null, error));
        }
    }, []);

    const getData = useCallback(async () => {
        const fromTimestamp = fromDate.getTime();
        const toTimestamp = toDate.getTime();
        await Promise.all(
            sites.map(async (site) => {
                // Remove readings and errors and set loading to true
                setData((data) => updateData(data, site.id, true, true, null, null));

                // Fetch readings and update loading status. Update errors as well
                await getSiteData(site, fromTimestamp, toTimestamp);
            }),
        );
    }, [sites, fromDate, toDate, getSiteData]);

    useEffect(() => {
        if (sites && fromDate && toDate) {
            getData();
        }
    }, [sites, fromDate, toDate, getData]);

    return data;
}

async function fetchData(siteId, fromTimestamp, toTimestamp) {
    const paramsObject = {
        fromDate: fromTimestamp,
        toDate: toTimestamp,
        useEmptyReadings: true,
        readingsOnly: true,
        withSummary: false,
    };
    const params = new URLSearchParams(paramsObject);
    const url = `${process.env.REACT_APP_API_BASE_URL}/analytics/${siteId}?${params}`;
    const token = await getAccessToken();
    const options = {
        user: {
            token,
            authenticated: true,
        },
    };
    const { status, headers, body, json } = await fetchUtils.fetchJson(url, options);
    if (status !== 200) {
        throw Error(`API Request Failed. Status Code: ${status}`);
    }
    return { readings: json.readings, nextFromDate: json.nextFromDate, headers, body, json };
}

function updateData(state, siteId, loading, resetReadings = false, readings = null, error = null) {
    const newState = state.map((siteData) => {
        if (siteData.id === siteId) {
            let newSiteData = {
                ...siteData,
                loading,
            };

            if (readings) {
                const formattedReadings = readings.map((reading) => [reading.x, reading.y]);
                newSiteData.readings = newSiteData.readings.concat(formattedReadings);
            }
            if (resetReadings) newSiteData.readings = [];
            if (error !== siteData.error) newSiteData.error = error;
            return newSiteData;
        }
        return siteData;
    });

    return newState;
}

export default useSitesLevel;
