import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    useDelete,
    useNotify,
    useRedirect,
    useRefresh,
    Confirm,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import { Button } from '@mui/material';
import GROUPS from '../auth/groups';
import { getAccessToken } from '../utils/authProvider';

const DeleteButton = () => {
    const record = useRecordContext();
    const [deleteOne, { data: deleted, isLoading: loading, error }] = useDelete();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { permissions } = usePermissions();

    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.CUSTOMER_ADMIN)
    ) {
        redirect('/');
    }

    const confirmDelete = async (event) => {
        event.preventDefault();

        deleteOne('users', { id: record.id, previousData: record });
        const token = await getAccessToken();
        await fetch(process.env.REACT_APP_API_OUTSYSTEMS_URL + `Users/${record.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ?? '',
            },
        });
    };

    React.useEffect(() => {
        if (error) {
            console.error(error);
            notify('User could not be deleted.', { type: 'warning' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    React.useEffect(() => {
        if (!deleted) return;
        setTimeout(() => {
            notify('User deleted.', { type: 'success' });
            redirect('/users');
            refresh(true);
        }, 600);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted]);
    return (
        <>
            <Button
                disabled={loading}
                onClick={() => setConfirmOpen(true)}
                startIcon={<DeleteIcon />}
                color="secondary"
                style={{ color: '#f44336' }}
            >
                Delete
            </Button>
            <Confirm
                isOpen={confirmOpen}
                title="Delete user"
                content="Are you sure you want to delete this user?"
                onConfirm={confirmDelete}
                onClose={() => setConfirmOpen(false)}
            />
        </>
    );
};

export default DeleteButton;
