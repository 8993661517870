import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './auth/azureADService';

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
