import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTime } from 'date-fns';
import { getAccessToken } from '../../../utils/authProvider';

const LevelDataContext = createContext({});

const LevelDataContextProvider = ({ children, deviceId, from, to }) => {
    const [level, setLevel] = useState({
        loading: null,
        data: null,
        error: null,
    });
    const [distAmp, setDistAmp] = useState({
        loading: null,
        data: null,
        error: null,
    });

    useEffect(() => {
        const updateLevel = async (fromTs, toTs) => {
            setLevel({
                loading: true,
                data: null,
                error: null,
            });
            const { data, error } = await fetchLevelData(deviceId, fromTs, toTs, 'level,level2');
            setLevel({
                loading: false,
                data: {
                    level: data?.level,
                    level2: data?.level2,
                },
                error,
            });
        };

        const updateDistAmp = async (fromTs, toTs) => {
            setDistAmp({
                loading: true,
                data: null,
                error: null,
            });
            const { data, error } = await fetchLevelData(deviceId, fromTs, toTs, 'a1,a2,d1,d2');
            setDistAmp({
                loading: false,
                data: {
                    a1: data?.a1,
                    a2: data?.a2,
                    d1: data?.d1,
                    d2: data?.d2,
                },
                error,
            });
        };

        const update = async () => {
            // Get timestamp in milliseconds
            const fromTimestamp = getTime(from);
            const toTimestamp = getTime(to);
            if (!fromTimestamp || !toTimestamp || fromTimestamp > toTimestamp) return;

            // Get promises
            const promiseLevel = updateLevel(fromTimestamp, toTimestamp);
            const promiseDistAmp = updateDistAmp(fromTimestamp, toTimestamp);

            // // Fetch data and update state
            await Promise.all([promiseLevel, promiseDistAmp]);
        };

        if (deviceId && from && to) {
            update();
        }
    }, [deviceId, from, to]);

    return (
        <LevelDataContext.Provider value={{ level, distAmp, from, to }}>
            {children}
        </LevelDataContext.Provider>
    );
};

const useLevelDataContext = () => {
    const context = useContext(LevelDataContext);
    if (!context) {
        throw new Error('useLevelDataContext must be used within a LevelDataContextProvider');
    }
    return context;
};

const fetchLevelData = async (deviceId, fromDate, toDate, attributes) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/leveldata?fromDate=${fromDate}&toDate=${toDate}&attributes=${attributes}`;
    const options = {
        method: 'GET',
    };
    let error;
    let data;

    const token = await getAccessToken();
    if (!options.headers) options.headers = new Headers({ Accept: 'application/json' });
    options.headers.append('Authorization', token);
    options.headers.append('Accept', 'application/json');
    try {
        const res = await fetch(url, options);
        const json = await res.json();
        if (res.status !== 200) {
            throw Error(JSON.stringify(json));
        }
        data = parseReadings(json.readings, attributes);
    } catch (err) {
        error = err;
    }

    return {
        data,
        error,
    };
};

const parseReadings = (readings, attributes) => {
    if (!readings || readings.length === 0) {
        return {};
    }

    const attrNames = attributes.split(',');
    const result = readings.reduce((prev, curr) => {
        attrNames.forEach((attr) => {
            if (Number.isFinite(curr[`${attr}`])) {
                const attrExists = Object.prototype.hasOwnProperty.call(prev, attr);
                if (!attrExists) prev[`${attr}`] = [];
                prev[`${attr}`].push([curr.timestamp, curr[`${attr}`]]);
            }
        });

        return prev;
    }, {});
    return result;
};

export { useLevelDataContext, LevelDataContextProvider };
