import { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/authProvider';

function useAnalytics(
    siteId,
    fromDate,
    toDate,
    useEmptyReadings = false,
    readingsOnly = false,
    withSummary = false,
) {
    // Basic info
    const [site, setSite] = useState(null);
    const [alarms, setAlarms] = useState(null);
    const [latestReading, setLatestReading] = useState(null);
    const [readings, setReadings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pagination
    const [nextFromDate, setNextFromDate] = useState(null);

    // Level summary
    const [summary, setSummary] = useState(null);
    const [levelSum, setLevelSum] = useState(null);
    const [peak, setPeak] = useState(null);
    const [count, setCount] = useState(null);

    // Basic info
    useEffect(() => {
        async function getData() {
            setLoading(true);
            setError(null);
            setLevelSum(null);
            setPeak(null);
            setCount(null);
            const tsFrom = fromDate.getTime();
            const tsTo = toDate.getTime();
            let token = await getAccessToken();
            const data = await fetchData(
                token,
                siteId,
                tsFrom,
                tsTo,
                useEmptyReadings,
                readingsOnly,
                withSummary,
            );

            // Error
            if (data.error) {
                if (data.status !== 204) setError(data.error);
                setSite(null);
                setAlarms(null);
                setLatestReading(null);
                setReadings([]);
                setSummary(null);
                setLevelSum(null);
                setPeak(null);
                setCount(null);
                setLoading(false);
                return;
            }

            // Basic info and first set of readings
            setSite(data.site);
            setAlarms(data.alarms);
            setLatestReading(data.latestReading);
            if (withSummary) {
                setLevelSum((prevSum) => {
                    if (prevSum === null) return data.sum;
                    if (data.sum === null) return prevSum;
                    return data.sum + prevSum;
                });
                setPeak((prevPeak) => {
                    if (prevPeak === null) return data.peak;
                    if (data.peak === null) return prevPeak;
                    return data.peak > prevPeak ? data.peak : prevPeak;
                });
                setCount((prevValue) => {
                    if (prevValue === null) return data.count;
                    if (data.count === null) return prevValue;
                    return prevValue + data.count;
                });
            }
            setReadings(data.readings);
            setNextFromDate(data.nextFromDate);
            if (!data.nextFromDate) {
                setLoading(false);
            }
        }
        if (siteId && fromDate && toDate) {
            getData();
        }
    }, [siteId, fromDate, toDate, useEmptyReadings, readingsOnly, withSummary]);

    // Add readings based on pagination
    useEffect(() => {
        async function getNextPage() {
            const tsFrom = fromDate.getTime();
            const tsTo = toDate.getTime();
            const token = await getAccessToken();
            setLoading(true);
            const readingsResult = await fetchData(
                token,
                siteId,
                tsFrom,
                tsTo,
                useEmptyReadings,
                readingsOnly,
                withSummary,
                nextFromDate,
            );

            // Error
            if (readingsResult.error) {
                if (readingsResult.status !== 204) setError(readingsResult.error);
                setReadings([]);
                setLoading(false);
                setLevelSum(null);
                setPeak(null);
                setCount(null);
                return;
            }
            setReadings((prevReadings) => [...prevReadings, ...readingsResult.readings]);
            if (withSummary) {
                setLevelSum((prevSum) => {
                    if (prevSum === null) return readingsResult.sum;
                    if (readingsResult.sum === null) return prevSum;
                    return readingsResult.sum + prevSum;
                });
                setPeak((prevPeak) => {
                    if (prevPeak === null) return readingsResult.peak;
                    if (readingsResult.peak === null) return prevPeak;
                    return readingsResult.peak > prevPeak ? readingsResult.peak : prevPeak;
                });
                setCount((prevValue) => {
                    if (prevValue === null) return readingsResult.count;
                    if (readingsResult.count === null) return prevValue;
                    return prevValue + readingsResult.count;
                });
            }
            setNextFromDate(readingsResult.nextFromDate);
            if (!readingsResult.nextFromDate) {
                setLoading(false);
            }
        }
        if (nextFromDate) {
            getNextPage();
        }
    }, [siteId, nextFromDate, fromDate, toDate, useEmptyReadings, readingsOnly, withSummary]);

    useEffect(() => {
        if (withSummary && !nextFromDate) {
            const average = levelSum && count && count > 0 ? levelSum / count : null;
            setSummary({
                peak,
                average,
            });
        }
    }, [levelSum, peak, count, nextFromDate, withSummary]);

    return {
        site,
        alarms,
        latestReading,
        readings,
        summary,
        loading,
        error,
    };
}

async function fetchData(
    token,
    siteId,
    from,
    to,
    useEmptyReadings,
    readingsOnly,
    withSummary,
    nextFrom,
) {
    let result;
    let paramsObject = { fromDate: from, toDate: to, useEmptyReadings, readingsOnly, withSummary };
    if (nextFrom) paramsObject.nextFromDate = nextFrom;
    const params = new URLSearchParams(paramsObject);
    const url = `${process.env.REACT_APP_API_BASE_URL}/analytics/${siteId}?${params}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ?? '',
            },
        });
        if (response.status === 200) {
            const payload = await response.json();
            result = {
                readings: payload.readings,
                nextFromDate: payload.nextFromDate,
            };

            if (!nextFrom) {
                result.id = payload.id;
                result.site = payload.site;
                result.latestReading = payload.latestReading;
                result.alarms = payload.alarms;
                result.alarmCount = payload.alarmCount;
            }

            if (withSummary) {
                result.sum = payload.levelSummary?.sum || null;
                result.peak = payload.levelSummary?.peak || null;
                result.count = payload.levelSummary?.count || null;
            }
        } else {
            result = {
                error: `HTTP error status: ${response.status}`,
            };
        }

        result.status = response.status;
    } catch (error) {
        result = {
            error: 'Fetch error',
        };
    }

    return result;
}

export default useAnalytics;
