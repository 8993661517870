import React, { useEffect, useState } from 'react';
import {
    SimpleShowLayout,
    TextField,
    EditButton,
    Button as AdminButton,
    useNotify,
    useRedirect,
    usePermissions,
    useRecordContext,
    useGetList,
} from 'react-admin';
import {
    Box,
    Grid,
    Card,
    List,
    ListItem,
    ListItemText,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material';
import Roles from './Roles';
import RolesAdmin from './RolesAdmin';
import getFriendlyRoleName from '../auth/getFriendlyRoleName';
import { listRolesForUser, forcePasswordReset } from '../utils/authProvider';
import GROUPS from '../auth/groups';
import Loading from '../components/Loading';

const PageLayout = () => {
    const user = useRecordContext();
    const [organisation, setOrganisation] = useState();
    const { data: organisationList, isLoading: organisationLoading } = useGetList(
        'organisations',
        {
            filter: {
                status: 0,
            },
            pagination: { page: 1, perPage: 1000 },
        },
        { enabled: !!user && !!user.organisationId },
    );
    const [showRoles, setShowRoles] = useState(false);
    const [roles, setRoles] = useState([]);
    const [resending, setResending] = useState(false);
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const notify = useNotify();

    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.CUSTOMER_ADMIN)
    ) {
        redirect('/');
    }

    useEffect(() => {
        const mapRoles = async () => {
            try {
                await listRolesForUser(user.userId)
                    .then((res) => {
                        if (res.status === 200) {
                            return res.json();
                        }
                    })
                    .then((json) => {
                        if (json && json.roles) {
                            const userRoles = json.roles;
                            const mappedGroups = userRoles.map((x) => x.name);
                            // eslint-disable-next-line security/detect-object-injection
                            const hswRoles = Object.keys(GROUPS).map((key) => GROUPS[key]);
                            // Filter out base role so we do not display it
                            setRoles(
                                mappedGroups.filter(
                                    (x) => x !== GROUPS.CUSTOMER_USER && hswRoles.includes(x),
                                ),
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                console.log(error);
            }
        };
        mapRoles();
    }, [showRoles, user.userId]);

    useEffect(() => {
        if (user && organisationList && organisationList.length >= 0) {
            const org = organisationList.find((o) => o.id === user.organisationId);
            setOrganisation(org);
        }
    }, [organisationList, user]);
    const resendCode = async () => {
        setResending(true);
        try {
            const response = await forcePasswordReset(user.id);
            const notifyMessage =
                response.status === 200
                    ? 'The user will receive an email with a password reset link.'
                    : 'Error while resending, the user may have already activated their account';
            const notifyType = response.status === 200 ? 'success' : 'error';
            notify(notifyMessage, {
                type: notifyType,
            });

            setResending(false);
        } catch (err) {
            setResending(false);
            notify(
                err && err.message
                    ? err.message
                    : 'Error while resending, the user may have already activated their account',
                { type: 'error' },
            );
        }
    };

    if (organisationLoading) return <Loading />;
    return (
        <>
            {organisation && organisation.isElevated ? (
                <RolesAdmin
                    user={user}
                    isOpen={showRoles}
                    onClose={() => setShowRoles(false)}
                    roles={roles}
                />
            ) : (
                <Roles
                    user={user}
                    isOpen={showRoles}
                    onClose={() => setShowRoles(false)}
                    roles={roles}
                />
            )}

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card>
                        <Box display="flex" justifyContent="space-between" m="15px">
                            <Typography variant="h6" gutterBottom>
                                User Details
                            </Typography>
                            <EditButton record={user} icon={null} />
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="name" label="Fullname" fullWidth />
                            <TextField source="emailAddress" label="Email" fullWidth />
                            <TextField source="phone" label="Mobile" fullWidth />
                        </SimpleShowLayout>
                    </Card>
                    <br />
                    {!user.isAzureADUser && (
                        <Button
                            color="primary"
                            variant="outlined"
                            size="large"
                            onClick={() => resendCode()}
                            disabled={resending}
                        >
                            Reset Password
                            {resending && (
                                <CircularProgress
                                    style={{ marginLeft: '8px' }}
                                    size={18}
                                    color="inherit"
                                />
                            )}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <SimpleShowLayout>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h6" gutterBottom>
                                    Roles
                                </Typography>
                                <AdminButton onClick={() => setShowRoles(true)} label="Edit" />
                            </Box>
                            <List>
                                {roles.map((role, key) => (
                                    <ListItem key={key} style={{ paddingLeft: '0px' }}>
                                        <ListItemText primary={getFriendlyRoleName(role)} />
                                    </ListItem>
                                ))}
                            </List>
                        </SimpleShowLayout>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
