import { jwtDecode } from 'jwt-decode';
import { Auth0Client } from '@auth0/auth0-spa-js';
import GROUPS from './groups';

const auth0_nameSpace = process.env.REACT_APP_AUTH0_NAMESPACE;
const auth0_clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const auth0_connection = process.env.REACT_APP_AUTH0_CONNECTION;
const auth0_logincallback = process.env.REACT_APP_AUTH0_LOGIN_CALLBACK;
export const auth0_logout_uri = process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI;

export const AUTH0_TOKEN_CONTEXT = {
    ROLES: `${auth0_nameSpace}/roles`,
    USER_ID: 'sub',
    ORGANISATION_ID: `${auth0_nameSpace}/hsw_organisationId`,
    EXPIRY: 'exp',
    EMAIL: `${auth0_nameSpace}/email`,
    HSW_USER_ID: `${auth0_nameSpace}/hsw_userId`,
};

const tokenRequiresRefresh = (access_token) => {
    const decode = jwtDecode(access_token);
    const now = new Date().getTime();
    const expiry = decode[AUTH0_TOKEN_CONTEXT.EXPIRY] * 1000 ?? now;
    return expiry < now + 10000;
};

const isTokenExpired = (access_token) => {
    const decode = jwtDecode(access_token);
    const expiry_unix = decode[AUTH0_TOKEN_CONTEXT.EXPIRY];
    const expiry = new Date(expiry_unix * 1000).getTime();
    const now = new Date().getTime();
    return expiry <= now;
};

export const auth0 = new Auth0Client({
    domain: auth0_domain,
    clientId: auth0_clientId,
    authorizationParams: {
        redirect_uri: auth0_logincallback,
        audience: auth0_audience,
        scope: 'openid profile email offline_access',
        connection: auth0_connection, // is this required?
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
});

export async function getAccessTokenAuth0() {
    try {
        let access_token = localStorage.getItem('hsw_auth');

        // Try to get token from Auth0
        if (!access_token || tokenRequiresRefresh(access_token)) {
            access_token = await auth0.getTokenSilently();
            localStorage.setItem('hsw_auth', access_token);
            localStorage.setItem('hsw_isAzureAD', false);
        }

        return access_token;
    } catch (error) {
        return null;
    }
}

export async function validateAccessToken(accessToken) {
    const decoded = jwtDecode(accessToken);
    const organisationId = !!decoded && decoded[AUTH0_TOKEN_CONTEXT.ORGANISATION_ID];
    const hswUserId = !!decoded && decoded[AUTH0_TOKEN_CONTEXT.HSW_USER_ID];
    const roles = !!decoded && decoded[AUTH0_TOKEN_CONTEXT.ROLES];

    if (!roles || roles.length === 0)
        throw Error(
            "You don't have permission to access this application. Your account may have been removed.",
        );
    if (!organisationId) throw Error("User doesn't have a valid Organization");
    if (!hswUserId) throw Error("User doesn't have a valid ID");
}

export async function checkAuth_Auth0() {
    try {
        await auth0.checkSession();
        const isAuthenticated = await auth0.isAuthenticated();

        if (!isAuthenticated) {
            return false;
        }

        const access_token = await getAccessTokenAuth0();
        if (!access_token) {
            throw Error('Unable to fetch access token');
        }
        const decodedToken = jwtDecode(access_token);
        const roles = decodedToken[AUTH0_TOKEN_CONTEXT.ROLES];

        if (isTokenExpired(access_token)) return false;

        if (!roles || roles.length === 0) return false;

        const includeAllowedRoles =
            roles.includes(GROUPS.HSW_ADMIN) ||
            roles.includes(GROUPS.HSW_USER) ||
            roles.includes(GROUPS.PORTALACCESS) ||
            roles.includes(GROUPS.CUSTOMER_ADMIN) ||
            roles.includes(GROUPS.CUSTOMER_USER);

        if (!includeAllowedRoles) return false;

        return true;
    } catch (error) {
        return false;
    }
}

export function getUserIdAuth0(token) {
    const decoded_token = jwtDecode(token);
    const userId = decoded_token[AUTH0_TOKEN_CONTEXT.USER_ID].replace('auth0|', '');
    return userId;
}

export function mapUserInfoAuth0(token, user) {
    const decoded_token = jwtDecode(token);
    return {
        organisationId: decoded_token[AUTH0_TOKEN_CONTEXT.ORGANISATION_ID],
        username: decoded_token[AUTH0_TOKEN_CONTEXT.USER_ID].replace('auth0|', ''),
        roles: decoded_token[AUTH0_TOKEN_CONTEXT.ROLES],
        email: decoded_token[AUTH0_TOKEN_CONTEXT.EMAIL],
        name: user.name,
        id: user.id,
        externalUserId: decoded_token[AUTH0_TOKEN_CONTEXT.USER_ID].replace('auth0|', ''),
    };
}
