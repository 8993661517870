import React, { useEffect, useState } from 'react';
import { useGetPermissions } from 'react-admin';
import MeasurementDiagram from './MeasurementDiagram';
import { Box, Typography } from '@mui/material';
import { getUserInfo } from '../../utils/authProvider';

const MeasurementSurvey = ({ site, onClose }) => {
    const [permissions, setPermissions] = useState([]);
    const getPermissions = useGetPermissions();
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const siteTypeId = site.siteTypeId;
    const siteStatus = site.status;

    useEffect(() => {
        async function getUser() {
            const user = await getUserInfo();
            if (user) {
                setUserId(user.id);
                setUserName(user.name);
            }
        }
        getUser();
    }, []);

    useEffect(() => {
        getPermissions().then((permissions) => setPermissions(permissions));
    }, [getPermissions]);

    return (
        <Box padding="8px" bgcolor="white">
            <Typography variant="h6" gutterBottom>
                {siteTypeId === 1 && 'Manhole Site Survey'}
                {siteTypeId === 2 && 'Raingarden Site Survey'}
                {siteTypeId === 4 && 'Groundwater Site Survey'}
            </Typography>
            <MeasurementDiagram
                record={site}
                permissions={permissions}
                userId={userId}
                userName={userName}
                isEditMode={true}
                isSurveyEdit={false}
                siteStatus={siteStatus}
                siteTypeId={siteTypeId}
                onClose={onClose}
            />
        </Box>
    );
};

export default MeasurementSurvey;
