import { jwtDecode } from 'jwt-decode';
import { PublicClientApplication } from '@azure/msal-browser';
import GROUPS from './groups';

const AZURE_AD_TOKEN_CONTEXT = {
    ROLES: 'groups',
    USER_ID: 'oid',
    EXPIRY: 'exp',
    EMAIL: 'upn',
    GIVEN_NAME: 'given_name',
    FAMILY_NAME: 'family_name',
};

const azureAD_Groups = {
    Installer: process.env.REACT_APP_AZURE_AD_INSTALLER,
    Surveyor: process.env.REACT_APP_AZURE_AD_SURVEYOR,
    Portal_Access: process.env.REACT_APP_AZURE_AD_PORTALACCESS,
    Portal_Admin: process.env.REACT_APP_AZURE_AD_PORTALADMIN,
    Analytics: process.env.REACT_APP_AZURE_AD_ANALYTICS,
};

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
        redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};
export const msalInstance = new PublicClientApplication(msalConfig);

const tokenRequiresRefresh = (access_token) => {
    const decode = jwtDecode(access_token);
    const now = new Date().getTime();
    const expiry = decode[AZURE_AD_TOKEN_CONTEXT.EXPIRY] * 1000 ?? now;
    return expiry < now + 10000;
};

const isTokenExpired = (access_token) => {
    const decode = jwtDecode(access_token);
    const expiry_unix = decode[AZURE_AD_TOKEN_CONTEXT.EXPIRY];
    const expiry = new Date(expiry_unix * 1000).getTime();
    const now = new Date().getTime();
    return expiry <= now;
};

async function acquireTokenSilent() {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts.find((a) => a?.tenantId === process.env.REACT_APP_AZURE_AD_TENANT_ID);
    const request = {
        ...loginRequest,
        account: account,
    };
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
}

function mapAzureADGroups(azureADGroupIds) {
    if (!azureADGroupIds) {
        return [];
    }

    const groups = [];
    azureADGroupIds.forEach((g) => {
        if (azureAD_Groups.Installer && g === azureAD_Groups.Installer) {
            groups.push(GROUPS.INSTALLER);
        } else if (azureAD_Groups.Surveyor && g === azureAD_Groups.Surveyor) {
            groups.push(GROUPS.SURVEYOR);
        } else if (azureAD_Groups.Portal_Access && g === azureAD_Groups.Portal_Access) {
            groups.push(GROUPS.PORTALACCESS);
        } else if (azureAD_Groups.Portal_Admin && g === azureAD_Groups.Portal_Admin) {
            groups.push(GROUPS.HSW_ADMIN);
        } else if (azureAD_Groups.Analytics && g === azureAD_Groups.Analytics) {
            groups.push(GROUPS.ANALYTICS);
        }
    });

    return groups;
}

export async function logoutAzureAD() {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts.find((a) => a?.tenantId === process.env.REACT_APP_AZURE_AD_TENANT_ID);
    msalInstance.logoutRedirect({
        account: account,
        postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_LOGOUT_URI,
    });
}

export async function getAccessTokenAzureAD() {
    try {
        let access_token = localStorage.getItem('hsw_auth');

        // Try to get token from Azure
        if (!access_token || tokenRequiresRefresh(access_token)) {
            access_token = await acquireTokenSilent();
            localStorage.setItem('hsw_auth', access_token);
            localStorage.setItem('hsw_isAzureAD', true);
        }
        return access_token;
    } catch (error) {
        return null;
    }
}

export const loginRequest = {
    scopes: [process.env.REACT_APP_AZURE_AD_APP_ID],
};

export async function checkAuth_AzureAD() {
    try {
        const access_token = await getAccessTokenAzureAD();
        const decodedToken = jwtDecode(access_token);
        const roles = mapAzureADGroups(decodedToken[AZURE_AD_TOKEN_CONTEXT.ROLES]);

        if (isTokenExpired(access_token)) return false;

        if (!roles || roles.length === 0) return false;

        const includeAllowedRoles =
            roles.includes(GROUPS.HSW_ADMIN) ||
            roles.includes(GROUPS.HSW_USER) ||
            roles.includes(GROUPS.PORTALACCESS) ||
            roles.includes(GROUPS.CUSTOMER_ADMIN) ||
            roles.includes(GROUPS.CUSTOMER_USER);

        if (!includeAllowedRoles) return false;

        return true;
    } catch (error) {
        return false;
    }
}

export function getUserIdAzureAD(token) {
    const decoded_token = jwtDecode(token);
    const userId = decoded_token[AZURE_AD_TOKEN_CONTEXT.USER_ID];
    return userId;
}

export function mapUserInfoAzureAD(token, user) {
    const decoded_token = jwtDecode(token);
    const roles = mapAzureADGroups(decoded_token[AZURE_AD_TOKEN_CONTEXT.ROLES]);
    return {
        organisationId: user?.organisationId,
        username: decoded_token[AZURE_AD_TOKEN_CONTEXT.EMAIL],
        roles: roles,
        email: decoded_token[AZURE_AD_TOKEN_CONTEXT.EMAIL],
        name: user.name,
        id: user.id,
        externalUserId: decoded_token[AZURE_AD_TOKEN_CONTEXT.USER_ID],
    };
}
